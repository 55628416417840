.footer-container {
    background: linear-gradient(0deg, #0375BC, #0375BC);
    color: white;
    padding: 15px 0;

    h5 {
        font-size: 1.125rem;
        font-family: 'Barlow Semi Condensed', sans-serif;
        font-weight: 600;
    }
}
.planning-announcement-detail {
    // border: 1px solid #f6f6f6;

    .content-html {
        img {
            width: 100%;
        }

        table {
            td {
                border: 1px solid #e1e1e1;
            }
        }
    }

    .header-result {
        height: 2.5rem;
        background: linear-gradient(90deg, #0375BC, #0375BC);
        padding: .375rem;

        .title {
            padding-top: .2rem;

            a {
                text-decoration: none;
                color: #ffffff;
                font-size: 1em;
                font-weight: 500;
                padding-left: 1rem;

                &:hover {
                    color: #ffffff;
                }
            }
        }
    }

    .content-result {
        margin-top: 1rem;

        ul {
            li {
                a {
                    text-decoration: none;
                }
            }
        }

        .item {
            margin-bottom: 1rem;
            // padding-right: .5rem;

            img {
                max-height: 31.25rem;
                object-fit: cover;
            }
        }
    }

    .menu-item-link {
        ul {
            li {
                display: block;
                width: 100%;
                float: left;
                margin-bottom: 3px;

                a {
                    display: block;
                    color: #3a3a3a;
                    background: #eeeeee;
                    font-size: 16px;
                    line-height: 32px;
                    border-bottom: 3px solid #eeeeee;
                    border-top: 3px solid #eeeeee;

                    &:hover {
                        text-decoration: none;
                        border-bottom: 3px solid #d92026;
                    }

                    span {
                        font-size: 24px;
                        width: 43px;
                        float: left;
                        height: 38px;
                        background: #d92026;
                        text-align: center;
                        line-height: 40px;
                        position: relative;
                        margin-right: 22px;
                        color: #fff;
                        top: -3px;

                        &::after {
                            content: "";
                            border: 19px solid transparent;
                            border-left: 12px solid #d92026;
                            position: absolute;
                            right: -31px;
                            top: 0;
                        }
                    }
                }

                &:nth-child(1) {
                    a {
                        &:hover {
                            border-bottom-color: #038FF4;
                        }

                        span {
                            background: #038FF4;

                            &::after {
                                border-left-color: #038FF4;
                            }
                        }
                    }
                }

                &:nth-child(2) {
                    a {
                        &:hover {
                            border-bottom-color: #ff0000;
                        }

                        span {
                            background: #ff0000;

                            &::after {
                                border-left-color: #ff0000;
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    a {
                        &:hover {
                            border-bottom-color: #40c161;
                        }

                        span {
                            background: #40c161;

                            &::after {
                                border-left-color: #40c161;
                            }
                        }
                    }
                }

                &:nth-child(4) {
                    a {
                        &:hover {
                            border-bottom-color: #4085d3;
                        }

                        span {
                            background: #4085d3;

                            &::after {
                                border-left-color: #4085d3;
                            }
                        }
                    }
                }

                &:nth-child(5) {
                    a {
                        &:hover {
                            border-bottom-color: #424242;
                        }

                        span {
                            background: #424242;

                            &::after {
                                border-left-color: #424242;
                            }
                        }
                    }
                }

                &:nth-child(6) {
                    a {
                        &:hover {
                            border-bottom-color: #927e66;
                        }

                        span {
                            background: #927e66;

                            &::after {
                                border-left-color: #927e66;
                            }
                        }
                    }
                }

                &:nth-child(7) {
                    a {
                        &:hover {
                            border-bottom-color: #FFFF00;
                        }

                        span {
                            background: #FFFF00;

                            &::after {
                                border-left-color: #FFFF00;
                            }
                        }
                    }
                }

                &:nth-child(8) {
                    a {
                        &:hover {
                            border-bottom-color: #c0601a;
                        }

                        span {
                            background: #c0601a;

                            &::after {
                                border-left-color: #c0601a;
                            }
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .collapse {
        .content-result {
            max-height: 250px;
            overflow-y: scroll;
        }

        .content-result::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #ffffff;
        }

        .content-result::-webkit-scrollbar {
            width: 8px;
            background-color: #ffffff;
        }

        .content-result::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #999;
        }

    }
}
@media (max-width: 767px) {
  .main-nav {
    height: 44px;
  }
  .navbar-toggler {
    margin-left: -15px;
    margin-top: 3px;
  }
  #navbarNav {
    position: absolute;
    z-index: 99999;
    background-color: #0375BC;
    border: solid 1px #CCCCCC;
    margin-left: -15px;
    top: 62px;
    width: 99%;
    padding: 10px;
  }

  .header-container .navbar ul.navbar-nav li.nav-item.padding-left-menu {
    padding-left: 0px !important;
  }

  .main-nav ul li ul.level1 {
    width: 100% !important;
  }

  .nav-item {
    border-bottom: solid 1px #FFFFFF;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .level1_mobile {
    max-width: 100% !important;
    width: 100% !important;

  }

  .header-container .navbar ul.navbar-nav li.nav-item.dropdown .dropdown-menu a {
    background-color: #038FF4;
  }

  #slider-home-page {
    display: none;
  }

  #home-banner {
    display: none;
  }
  #mobile-account-link {
    display: block;
  }
  .container {
    max-width: 100% !important;
  }

  .planning-announcement .header-doing .input-search {
    width: 150px !important;
  }

  #map-tool-panel-right {
    top: -50px;
    right: 100px;
    z-index: 9999;
  }

  #map-tool-list {
    z-index: 9999;
    top: 0px;
    width: 318px !important;
  }

  #right-panel-tool-filter-box {
    top: 90px;
    right: -10px;
    width: 320px;
    border-radius: 1px;
  }
  #map-tool-panel-right-search {
    display: none;
  }
  #map-tool-panel-right-base-map {

  }

  #map-tool-panel-right-base-map .select-base-map {
    left: 10px;
  }

  #planning-infomation-small-popup {
    right: -182px;
    top: 50px;
  }
  #map-tool-panel-right-search-result {
    position: relative;
    top: 60px !important;
    left: -40px !important;
    z-index: 1 !important;
  }

  #map-tool-panel-right-search-result form {
    width: 336px !important;
    z-index: 1 !important;
  }

  #map-tool-panel-right-search-result .map__autocomplete-container {
    width: 336px !important;
    left: 0px !important;
    top: 42px !important;
  }

  #map-tool-panel-right-search-result-action {
    width: 336px !important;
    z-index: 1 !important;
  }
  #map-tool-panel-right-search-result-action img {
    display: inline-block !important;
    z-index: 1 !important;
  }

}